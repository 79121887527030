html {
	height: 100%;
	max-height: 100%;
	font-size: 10px;
	-webkit-tap-highlight-color: transparent;
}

body {
	height: 100%;
	max-height: 100%;
	font-family: -apple-system,BlinkMacSystemFont,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Segoe UI","PingFang SC","Hiragino Sans GB","Microsoft YaHei","Helvetica Neue",Helvetica,Arial,sans-serif;
	letter-spacing: 0.01rem;
	font-size: 1.8em;
	line-height: 1.75em;
	color: #3A4145;
	font-weight: 400;
	-webkit-font-feature-settings: 'kern' 1;
	-moz-font-feature-settings: 'kern' 1;
	-o-font-feature-settings: 'kern' 1;
	text-rendering: geometricPrecision;
}

h1,
h2,
h3,
h4,
h5,
h6,
input,
label,
select,
textarea,
table {
	-webkit-font-feature-settings: 'dlig' 1, 'liga' 1, 'lnum' 1, 'kern' 1;
	-moz-font-feature-settings: 'dlig' 1, 'liga' 1, 'lnum' 1, 'kern' 1;
	-o-font-feature-settings: 'dlig' 1, 'liga' 1, 'lnum' 1, 'kern' 1;
	font-family: "Open Sans", sans-serif;
	text-rendering: geometricPrecision;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	color: #2E2E2E;
	line-height: 1.15em;
	margin: 1em 0 1em 0;
	font-weight: 600;
}

h1 {
	font-size: 3rem;
	letter-spacing: -2px;
	text-indent: -3px;
}

h2 {
	font-size: 2.6rem;
	letter-spacing: -1px;
}

h3 {
	font-size: 2.2rem;
}

h4 {
	font-size: 2rem;
}

h5 {
	font-size: 1.8rem;
}

h6 {
	font-size: 1.4rem;
}

a {
	color: #4A4A4A;
	transition: color 0.2s ease;
}

a:hover {
	color: #111;
}

p,
ul,
ol,
dl,
figure {
	-webkit-font-feature-settings: 'liga' 1, 'onum' 1, 'kern' 1;
	-moz-font-feature-settings: 'liga' 1, 'onum' 1, 'kern' 1;
	-o-font-feature-settings: 'liga' 1, 'onum' 1, 'kern' 1;
	margin: 0 0 1em 0;
	text-rendering: geometricPrecision;
}

ol,
ul {
	padding-left: 3rem;
}

ol ol,
ul ul,
ul ol,
ol ul {
	margin: 0 0 0.4em 0;
	padding-left: 2em;
}

dl dt {
	float: left;
	width: 180px;
	overflow: hidden;
	clear: left;
	text-align: right;
	text-overflow: ellipsis;
	white-space: nowrap;
	font-weight: 700;
	margin-bottom: 1em;
}

dl dd {
	margin-left: 200px;
	margin-bottom: 1em;
}

li {
	margin: 0.4em 0;
}

li li {
	margin: 0;
}

hr {
	display: block;
	height: 1px;
	border: 0;
	border-top: #eee 1px solid;
	margin: 3.2em 0;
	padding: 0;
}

mark {
	background-color: #fdffb6
}

kbd {
	display: inline-block;
	margin-bottom: 0.4em;
	padding: 1px 8px;
	border: #CCC 1px solid;
	color: #666;
	text-shadow: #FFF 0 1px 0;
	font-size: 0.9em;
	font-weight: 700;
	background: #F4F4F4;
	border-radius: 4px;
	box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2), 0 1px 0 0 #fff inset;
}

@media only screen and (max-width: 900px) {
	blockquote {
		margin-left: 0;
	}

	hr {
		margin: 2.4em 0;
	}

	ol,
	ul {
		padding-left: 2em;
	}

	h1 {
		font-size: 4.5rem;
		text-indent: -2px;
	}

	h2 {
		font-size: 3.6rem;
	}

	h3 {
		font-size: 3.1rem;
	}

	h4 {
		font-size: 2.5rem;
	}

	h5 {
		font-size: 2.2rem;
	}

	h6 {
		font-size: 1.8rem;
	}
}

@media only screen and (max-width: 500px) {
	hr {
		margin: 1.75em 0;
	}

	p,
	ul,
	ol,
	dl {
		font-size: 0.95em;
		margin: 0 0 2.5rem 0;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin: 0 0 0.3em 0;
	}

	h1 {
		font-size: 2.8rem;
		letter-spacing: -1px;
	}

	h2 {
		font-size: 2.4rem;
		letter-spacing: 0;
	}

	h3 {
		font-size: 2.1rem;
	}

	h4 {
		font-size: 1.9rem;
	}

	h5 {
		font-size: 1.8rem;
	}

	h6 {
		font-size: 1.8rem;
	}
}
